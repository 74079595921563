import React from 'react'
import { graphql } from 'gatsby'
import ReactAudioPlayer from 'react-audio-player'

import Layout from '../components/layout'
import SEO from '../components/seo'

import sanitizeHtml from '../components/sanitize'

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      {loadPodcasts(data)}
    </Layout>
  )
}

const loadPodcasts = data => {
  //sort the order, because for some reason it's misordered.
  data.allPodcastRssFeedEpisode.nodes.sort(
    (a, b) => new Date(b.item.isoDate) - new Date(a.item.isoDate)
  )
  const episodes = data.allPodcastRssFeedEpisode.nodes.map(episode => {
    const {
      item: { content, link, title },
    } = episode
    return (
      <div class="card">
        <div class="episodeTitle">{title}</div>
        <ReactAudioPlayer src={link} controls />
        <div class="episodeNotes">{sanitizeHtml(content)}</div>
      </div>
    )
  })
  return episodes
}

export const query = graphql`
  query PodcastQuery {
    allPodcastRssFeedEpisode(limit: 100) {
      nodes {
        item {
          isoDate
          title
          content
          link
          itunes {
            duration
          }
        }
      }
    }
  }
`
