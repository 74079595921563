import sanitizeHtml from 'sanitize-html'
import React from 'react'

const removePinecastText = text => {
  return text
    .split('\n')
    .filter(line => !line.includes('This podcast is powered'))
    .join('')
}

const retainNewLines = text => {
  return text.replace(/\n/, '<br>')
}
export default text => {
  const stripped = removePinecastText(text)

  const clean = sanitizeHtml(stripped, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'li', 'ul'],
    allowedAttributes: {
      a: ['href', 'target'],
    },
  })
  return <div dangerouslySetInnerHTML={{ __html: clean }} />
}
